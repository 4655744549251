<script setup>
    import { print } from 'graphql';
    import { reachGoal } from '~/utils/metrika';

    const client = useUser();

    try {
        /*
        const worker = new SharedWorker(new URL('../web-worker/subscribtion.worker.ts', import.meta.url), {
            name: 'uogorod',
            type: 'module'
        });

        worker.port.start();
        worker.port.postMessage({
            name: 'subscribe',
            payload: {
                operationName: 'Dialogs',
                query: print(DialogsDocument),
                variables: {
                    client_id: client.value?.client_id
                }
            }
        });
        */
    } catch(err) {

    }


	const TIME_TO_SHOW_ACHIVMENTS = 5000;
	const date = ref(new Date());
	const { data } = await useUserAchivmentsSubscription({
		variables: {
			id: client.value?.client_id,
			created_at: date.value
		}
	});

	setInterval(() => date.value = Date.now(), 1000);

    const onboarding_achivments = [
        'JOURNAL_GARDEN',
        'JOURNAL_PLANT',
        'JOURNAL_NOTE',
        'JOURNAL_TABLE',
        'JOURNAL_PRINT',
        'JOURNAL_WELCOME',
    ];

    watch(data, (data) => {
        data.uogorod_user_achivments.forEach(({ achivment }) => {
            if (onboarding_achivments.includes(achivment.id)) {
                reachGoal(`achivment:${achivment.id}`, { id: client.value?.client_id });

                if (achivment.id == 'JOURNAL_WELCOME') {
                    addModal({
                        component: defineAsyncComponent(() => import('@/components/Modals/journal/Welcome.vue')),
                    })
                }
            }
        });
    });
</script>

<template>
    <div class="fixed right-2 bottom-2 z-40  flex flex-col-reverse gap-2">
        <figure class="flex gap-4 m-0 items-center max-w-sm text-sm bg-white rounded-xl p-4 shadow border-0.5 animation-slide-up" v-for="(item, index) in data?.uogorod_user_achivments.filter(item => date - new Date(item.created_at) < TIME_TO_SHOW_ACHIVMENTS)"  :style="`opacity: ${1 - index / 10}; animation-delay: ${index / 2}s`">
            <img class="w-auto bg-transparent flex-shrink-0" :src="`/images/achivments/${item.achivment.icon}`" :alt="item.achivment.name" width="64" height="64" loading="lazy">
            <figcaption>
                <h4 class="m-0 troboto text-base">
                    Открыто достижение: {{ item.achivment.name }}	
                </h4>
                {{ item.achivment.description }}
            </figcaption>
        </figure>
    </div>
</template>