<script setup lang="ts">
    import { useAttrsObserver } from '@/composables/useAttrsObserver';

    const props = defineProps(['item', 'open', 'isFetching', 'active']);
    const emit = defineEmits(['update:open']);
    const details:Ref<Node | null> = ref(null);

    onMounted(() => {
        const { observer } = useAttrsObserver({
            open: (value: string) => emit('update:open', details.value?.open)
        });

        observer.observe(details.value as Node, { attributes: true });
        onBeforeUnmount(() => observer.disconnect());
    });
</script>

<template>
    <details :open="open || active?.path?.includes(item.path)" ref="details">
        <summary :class="['flex items-center justify-between gap-2 py-1.5', {'summary--has-marker': item.childrens}]">
            <!--<img v-else-if="isFetching" src="@/assets/icons/loader.svg" width="20" height="20" />-->
            <nuxt-link class="flex flex-1 items-center gap-2 font-medium" :to="item.path" :class="{'router-link-active': active?.path?.includes(item.path)}">
                <slot name="icon" v-bind="item">
                    <span class="text-xl" v-if="item.emoji">
                        {{ item.emoji }}
                    </span>      
                </slot>
                {{ item.title }}
            </nuxt-link>
            <slot name="end" v-bind="item"></slot>
        </summary>
       
        <div class="grid gap-0.5 ml-3 text-sm" v-if="item.childrens?.length && (item.open || active?.path?.includes(item.path))">
            <Tree v-for="child in item.childrens" :key="child.id" :item="child" v-model:open="child.open" :active="active">
                <template #icon>
                    <slot name="icon" v-bind="child"></slot>
                </template>
                <template #end>
                    <slot name="end" v-bind="child"></slot>
                </template>
            </Tree>
        </div>
    </details>
</template>
