export const useAttrsObserver = (mutationCallbacks: any) => {
  const observer = new MutationObserver((mutaion) => {
    const { attributeName, target } = mutaion[0]
    const key = attributeName ?? ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = target[key] || target.getAttribute(key)

    if (mutationCallbacks[key]) {
      mutationCallbacks[key](value)
    }
  })

  return { observer }
}
