<template>
	<header class="sticky top-0 z-40 text-white">
		<div class="container flex items-center justify-between p-4">
			<button class="lg:hidden flex items-center mr-2" form="toggle-menu" aria-label="Навигация">
				<svg-icon name="menu" width="32" height="32"></svg-icon>
				<svg-icon class="hidden" name="close" width="32" height="32"></svg-icon>
			</button>
			<nuxt-link to="/" class="flex items-center text-2xl lg:mr-auto troboto">
				<img class="rounded-full mr-2 w-8 flex-shrink-0" src="@/assets/icons/uogorod-logo-48x48px.png" width="32" height="32" alt="Сообщество Умный Огород" />
				Умный огород
			</nuxt-link>
			<div class="flex">
				<slot name="end">
					<nuxt-link to="/auth/signin">
						Войти
					</nuxt-link>
				</slot>
			</div>
		</div>
	</header>
</template>