<script setup>
	const currentYear = new Date().getFullYear();
	const links = {
		main: [
			{
				title: 'Лента',
				path: '/feed'
			},
			{
				title: 'Сообщество',
				path: '/profiles'
			},
			{
				title: 'Каталог',
				path: '/catalog'
			},
			{
				title: 'Руководства',
				path: '/manuals',
			},
		],
		catalog: [
			{
				title: 'Кустарники и Деревья',
				path: '/catalog/kustarniki-i-derevya',
			},
			{
				title: 'Овощи',
				path: '/catalog/ovoschi',
			},
			{
				title: 'Травы и Зелень',
				path: '/catalog/travy-i-zelen',
			},
			{
				title: 'Уход за растениями',
				path: '/catalog/ukhod-za-rasteniyami',
			},
			{
				title: 'Цветы и комнатные растения',
				path: '/catalog/tsvety-i-komnatnye-rasteniya',
			},
			{
				title: 'Ягоды',
				path: '/catalog/yagody',
			},
			{
				title: 'Бренды',
				path: '/catalog/brands',
			}
		]
	};
</script>

<template>
    <footer class="bg-white py-16 pb-8 px-4 xs:px-0">
		<div class="container">
			<div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
				<div>
					<a aria-current="page" href="/" class="flex items-center text-2xl lg:mr-auto troboto">
						<img class="rounded-full mr-2 w-8" src="@/assets/icons/uogorod-logo-48x48px.png" width="32" height="32" alt="Сообщество Умный Огород" />
						Умный огород
					</a>

					<p class="mt-4 w-full text-black/m text-sm">
						«Uogorod» - Место где вы можете общаться, обмениваться информацией, делиться своими достижениями, а также наблюдать за своим огородом в электронном дневнике садовода.
					</p>
					<a href="https://t.me/+ycnelp0LSOYyYjNi" target="_blank" rel="nofollow" class="mr-2 opacity-50 hover:opacity-100"><svg-icon name="baseline-telegram" width="32" height="32"/></a>
					<a href="https://dzen.ru/uogorod" target="_blank" rel="nofollow" class="mr-2 opacity-50 hover:opacity-100""><svg-icon name="yandex-zen" width="32" height="32"/></a>
					<a href="https://www.youtube.com/@uogorod" target="_blank" rel="nofollow" class="mr-2 opacity-50 hover:opacity-100"><svg-icon name="youtube-round" width="32" height="32"/></a>
				</div>
				

				<div class="grid grid-cols-2 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
					<div class="flex flex-col gap-4">
						<h3 class="m-0 font-medium text-gray-900">Аккаунт</h3>
							
						<div class="grid gap-2 text-sm">
							<nuxt-link class="text-black/m" to="/auth/signin">Вход</nuxt-link>
							<nuxt-link class="text-black/m" to="/auth/signup">Регистрация</nuxt-link>
						</div>
					</div>

					<div class="flex flex-col gap-4">
						<h3 class="m-0 font-medium text-gray-900">Разделы</h3>
							
						<div class="grid gap-2 text-sm">
							<nuxt-link class="text-black/m" :to="item.path" :item="item" v-for="item in links.main" :key="item.path">{{item.title}}</nuxt-link>
						</div>
					</div>

					<div class="flex flex-col gap-4">
						<h3 class="m-0 font-medium text-gray-900">Каталог</h3>
							
						<div class="grid gap-2 text-sm">
							<nuxt-link class="text-black/m" :to="item.path" :item="item" v-for="item in links.catalog" :key="item.path">{{item.title}}</nuxt-link>
						</div>
					</div>

					<div class="flex flex-col gap-4">
						<h3 class="m-0 font-medium text-gray-900">Информация</h3>

						<div class="grid gap-2 text-sm">
							<nuxt-link class="text-black/m" to="/help/info">Как пользоваться сайтом</nuxt-link>
							<nuxt-link class="text-black/m" to="/agreement">Пользовательское соглашение</nuxt-link>
							<nuxt-link class="text-black/m" to="/privacy">Политика конфиденциальности</nuxt-link>
						</div>

					</div>

				</div>
			</div>
			<div class="w-[150px]">
				<iframe src="https://yandex.ru/sprav/widget/rating-badge/46488722522?type=rating" width="150" height="50" frameborder="0"></iframe>
			</div>
			<p class="text-xs text-gray-500">2016 - {{currentYear}} Умный Огород - сообщество садоводов-огородников</p>
		</div>
	</footer>
	<svg class="clip-rect h-0 overflow-hidden">
		<defs>
			<path id="star" d="M6.52447 0.463524C6.67415 0.00286841 7.32585 0.00286996 7.47553 0.463525L8.68386 4.18237C8.75079 4.38838 8.94277 4.52786 9.15938 4.52786H13.0696C13.554 4.52786 13.7554 5.14767 13.3635 5.43237L10.2001 7.73075C10.0248 7.85807 9.95149 8.08375 10.0184 8.28976L11.2268 12.0086C11.3764 12.4693 10.8492 12.8523 10.4573 12.5676L7.29389 10.2693C7.11865 10.1419 6.88135 10.1419 6.70611 10.2693L3.54267 12.5676C3.15081 12.8523 2.62357 12.4693 2.77325 12.0086L3.98157 8.28976C4.04851 8.08375 3.97518 7.85807 3.79994 7.73075L0.636495 5.43237C0.244639 5.14767 0.446028 4.52786 0.93039 4.52786H4.84062C5.05723 4.52786 5.24921 4.38838 5.31614 4.18237L6.52447 0.463524Z"/>	
			<mask id="stars-fill" fill="#fff">
				<use href="#stars"/>
			</mask>
			<mask id="stars-outline">
				<use href="#stars"/>
			</mask>
			<g id="stars" stroke="#fff">
				<use href="#star" transform="translate(0,1)" />
				<use href="#star" transform="translate(16,1)" />
				<use href="#star" transform="translate(32,1)" />
				<use href="#star" transform="translate(48,1)" />
				<use href="#star" transform="translate(64,1)" />
			</g>
		</defs>
		<symbol id="rating-stars">
			<rect height="16" width="80" mask="url(#stars-fill)" style="fill: var(--fill-default, transparent)"/>
			<rect height="16" width="80" mask="url(#stars-outline)" style="fill: var(--fill-default, var(--fill, currentColor))"/>
			<rect height="16" mask="url(#stars-fill)" style="fill: var(--fill, currentColor); width: calc(100% / 5 * var(--rating))"/>
		</symbol>
	</svg>
</template>