<script setup>
import { useDialogsSubscription } from "~/composables/urql";
import CommonModals from "~/components/Common/Modals.vue";
// FIXME нужно иметь возможность сделать несколько разных инстансов нотификаций / модалок
import { notifications } from "@/stores/notification";

const props = defineProps(["links"]);
const route = useRoute();
useHead(() => ({
    link: [
        {
            rel: "canonical",
            href: "https://uogorod.ru" + route.path,
        },
    ],
}));
const client = useUser();
const authorizedLinks = computed(() => {
    return (
        client.value
            ? [
                  {
                      title: "Журнал",
                      path: `/profiles/${client?.value.client_id}/gardens`,
                      icon: "fluent:feed-28-regular",
                  },
              ]
            : []
    ).concat(props.links);
});
const isMenuOpen = useState("drawer", () => false);
const toggleMenu = () => (isMenuOpen.value = !isMenuOpen.value);

const logout = async () => {
    await useFetch("/api/auth/logout");
    navigateTo("/");
};

const emitter = useEmitter();
const chat = reactive({
    isOpen: false,
});

emitter.on("chat:close", () => (chat.isOpen = false));
emitter.on("chat:open", (profile) => {
    chat.isOpen = true;
    chat.profile = profile;
    chat.component = defineAsyncComponent(
        () =>
            import(`@/components/Chat/${profile ? "Messages" : "Dialogs"}.vue`),
    );
});

// TODO хорошо бы перенести куда-то отдельно
const variables = computed(() => ({ client_id: client.value?.client_id }));
const { data, executeSubscription } = useDialogsSubscription({
    variables,
    pause: true,
});
const unreadedCount = computed(
    () => data.value?.dialogs.filter((d) => d.is_new).length,
); //

watchEffect(() => client.value?.client_id && executeSubscription());

useSeoMeta({
    ogSiteName: "Умный огород",
});
</script>

<style>
@media (max-width: 1023px) {
    .navigation {
        @apply bg-white shadow-lg p-4;
    }
}
.navigation .router-link-active {
    @apply text-green-600 font-medium;
}
body:has(.drawer--opened) [form="toggle-menu"] svg:not(.hidden) {
    display: none;
}
body:has(.drawer--opened) [form="toggle-menu"] svg.hidden {
    display: block;
}

.fixed:has(.banner--telegram-chat:hover) .banner--journal {
    @apply translate-y-0;
}
</style>

<template>
    <form id="toggle-menu" @submit.prevent="() => toggleMenu()"></form>
    <CommonHeader class="bg-green-600">
        <template v-if="client?.client_id" #end>
            <button
                class="relative flex items-center ml-auto mr-4"
                @click="() => emitter.emit('chat:open')"
                type="button"
                tabindex="0"
            >
                <svg-icon name="mail" width="24" height="24" />
                <span
                    v-if="unreadedCount"
                    class="absolute -bottom-1 -right-1 bg-red-600 rounded-lg w-4 h-4 grid place-items-center text-[10px]"
                    >{{ unreadedCount }}</span
                >
            </button>
            <div class="flex items-center text-black/h">
                <form
                    class="group relative grid place-content-center cursor-pointer"
                    :key="client"
                >
                    <button
                        class="rounded-full overflow-hidden ring -my-1"
                        aria-label="Профиль"
                        type="button"
                        tabindex="0"
                    >
                        <Userpic
                            class="w-auto block pointer-events-none"
                            :src="`/files/userpic/${client?.client_id}.webp`"
                            width="32"
                            height="32"
                            :name="client?.name"
                        />
                    </button>
                    <div
                        class="dropdown absolute top-10 right-0 bg-white rounded-xl shadow-lg border-[0.5px] p-4 grid gap-1 whitespace-nowrap place-items-start"
                    >
                        <nuxt-link
                            tabindex="0"
                            class="relative flex items-center gap-2"
                            :to="{
                                path: `/profiles/${client?.client_id}/gardens`,
                            }"
                            prefetch-on="interaction"
                        >
                            <svg-icon
                                name="account-circle-outline"
                                width="16"
                                height="16"
                            />
                            Журнал
                        </nuxt-link>
                        <nuxt-link
                            tabindex="0"
                            class="relative flex items-center gap-2"
                            :to="{ path: `/profiles/${client?.client_id}` }"
                            prefetch-on="interaction"
                        >
                            <svg-icon
                                name="account-circle-outline"
                                width="16"
                                height="16"
                            />
                            Мой профиль
                        </nuxt-link>
                        <nuxt-link
                            tabindex="0"
                            class="flex items-center gap-2"
                            :to="{ path: `/feed/new/edit` }"
                        >
                            <svg-icon
                                name="note-plus-outline"
                                width="16"
                                height="16"
                            />
                            Добавить пост
                        </nuxt-link>
                        <nuxt-link
                            tabindex="0"
                            class="flex items-center gap-2"
                            :to="{
                                path: `/profiles/${client?.client_id}/edit`,
                            }"
                        >
                            <svg-icon
                                name="gear-outline"
                                width="16"
                                height="16"
                            />
                            Настройки
                        </nuxt-link>
                        <button
                            class="flex items-center gap-2 font-normal"
                            @click="logout"
                            type="button"
                            tabindex="0"
                        >
                            <svg-icon
                                name="power-standby"
                                width="16"
                                height="16"
                            />
                            Выход
                        </button>
                    </div>
                </form>
            </div>
        </template>
    </CommonHeader>

    <div class="w-full max-w-screen-xl mx-auto flex flex-1">
        <button
            class="bg-black/l fixed inset-0 z-30 lg:contents"
            v-if="isMenuOpen"
            form="toggle-menu"
        ></button>
        <div
            id="nav"
            :class="[
                'xs:scrollbar box-content navigation lg:flex flex-col fixed w-60 lg:sticky top-16 overflow-auto z-30 py-4 pr-2 mr-4 flex-shrink-0 lg:ml-4',
                isMenuOpen ? 'flex drawer--opened' : 'hidden',
            ]"
            style="height: calc(100vh - 64px)"
        >
            <Tree
                :item="item"
                v-for="item in authorizedLinks"
                :key="item.path"
                v-model:open="item.open"
                :active="route"
            >
                <template #icon="item">
                    <svg-icon
                        v-if="item.icon"
                        class="flex-shrink-0"
                        :name="item.icon"
                        width="20"
                        height="20"
                    />
                </template>
            </Tree>
        </div>
        <main
            class="flex flex-col gap-4 xs:py-4 flex-1 max-w-full oveflow-auto"
        >
            <slot />
        </main>
    </div>
    <ClientOnly>
        <NotificationAchivment v-if="client?.client_id" />
        <component
            v-if="chat.isOpen"
            :is="chat?.component"
            :profile="chat.profile"
            :dialogs="data.dialogs"
        ></component>
        <CommonModals />
        <div class="fixed top-24 right-4 grid gap-4 z-30">
            <!-- Стандартные уведомления (error|warn|info) -->
            <Notification
                :notification="notification"
                v-for="notification in notifications"
            />
        </div>
        <div class="fixed grid left-0 bottom-0 z-50 gap-4 p-4">
            <div
                class="banner--telegram-chat bg-sky-500 rounded-2xl overflow-hidden max-w-[345px] shadow-xl"
            >
                <div class="flex gap-4">
                    <div
                        class="text-white text-2xl troboto p-6 leading-relaxed"
                    >
                        Telegram Чат
                        <small class="block text-xl"
                            ><span
                                class="bg-white/20 py-0.5 px-2 rounded text-yellow-300"
                                >Uogorod.ru</span
                            ></small
                        >
                    </div>
                    <svg-icon
                        class="text-sky-500 block w-12 h-12 ml-auto"
                        name="baseline-telegram"
                    />
                </div>
                <div class="py-4 px-6 text-sm flex flex-col gap-4 bg-white">
                    Привет! Это Александр Устюхин, создатель uogorod.ru. Любые
                    вопросы пишите в Телеграм чат по ссылке ниже. Там же
                    обсуждаем предложения по развитию сайта.
                    <CommonButton
                        class="text-white bg-sky-600 hover:bg-sky-500 py-3 px-6 self-center"
                        to="https://t.me/+ycnelp0LSOYyYjNi"
                        target="_blank"
                        >Перейти</CommonButton
                    >
                </div>
            </div>
            <BannersJournal class="w-full" v-if="!client?.client_id" />
        </div>
    </ClientOnly>
    <CommonFooter />
</template>
