<script setup ="ts">
    const route = useRoute();
    const isClosed = useCookie('uogorod.b-journal-closed', { default: () => false });
</script>

<template>
    <div class="banner--journal rounded-2xl overflow-hidden max-w-72 shadow-xl" v-if="route.path != '/' && !isClosed">
        <div class="flex gap-4 relative">
            <img class="w-full h-auto hidden sm:inline" src="@/assets/garden-journal.webp" height="170">
            <CommonButton class="absolute right-2 top-2 text-black p-1" @click="() => isClosed = true">
                <svg-icon name="close" width="18" height="18"/>
            </CommonButton>
        </div>
        <div class="py-4 px-4 flex flex-col gap-1 bg-white">
            <div class="text-xl font-bold">Дневник садовода</div>
            <p class="m-0">В вашем телефоне. Бесплатно.</p>
            <CommonButton class="button bg-green-600 hover:bg-emerald-600 text-white mt-1" to="/">Хочу!</CommonButton>
        </div>
    </div>
</template>